<template>
  <div>
    <!-- title 组件 -->
    <div class="content-tile">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>公司管理文档</span>
      </div>
      <div>
        <el-upload
            :action="uploadFileUrl"
            :show-file-list="false"
            list-type="text"
            :auto-upload="true"
            :on-error="uploadFileError"
            :on-success="uploadFileSuccess"
            :multiple="false"
            name="attachment"
            :data="uploadFileField"
        >
          <el-button type="primary" v-if="this.type == '综合管理部' || this.type == '总经办'">点击上传</el-button>
        </el-upload>
      </div>
    </div>
    <common-document :uploadFileData="doc" :type = 'type'/>
  </div>
</template>
<script>
import {addDoc, getGsDoc} from "@/api/document_management/contract_template";

export default {
  props: [],
  components: {},
  data() {
    return {
      type: '',
      doc:[],
      uploadFileField: {remarks: 1}, //上传添加的字段
      uploadFileUrl: process.env.VUE_APP_URL + '/api/common/uploadFile',
    }
  },
  created() {
    this.initLoad()
  },
  mounted() {

  },
  methods: {
    initLoad() {
      this.type = localStorage.getItem("departmentName")
      getGsDoc().then(res=>{
        for (let i = 0; i<res.data.data.length; i++){
          res.data.data[i].url = res.data.data[i].attachment_json
        }
        this.doc = res.data.data
      })
    },
    //附件 上传失败的钩子
    uploadFileError() {
      this.$message.error('附件上传失败');
    },
    //附件 上传成功钩子
    uploadFileSuccess(file) {
      console.log(file)
      let data = {
        file_name: file.data.file_name,
        attachment_json: file.data.url
      }
      addDoc(data).then(res=>{
        console.log(res)
        this.$message.success('上传成功');
        this.initLoad()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.content-tile {
  @include flex(nowrap, space-between, center);
  background: $title-bg;
  border: 1px solid $title-bg;
  padding: 8px;
  margin-bottom: 12px;

  .su-title {
    font-weight: bold;
    @include flex();

    i {
      font-size: 16px;
      color: $title-icon;
      font-weight: bold;
      margin-right: 8px;
    }
  }
}
</style>